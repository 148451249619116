import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";
import { StylesProvider, ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-mono/300.css";
import "@fontsource/roboto-mono/400.css";
import "@fontsource/roboto-mono/500.css";
import "@fontsource/roboto-mono/700.css";
import App from "./App";

const theme = responsiveFontSizes(
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          padding: "2rem",
        },
        paddingCheckbox: {
          padding: "0 0.25rem",
        },
      },
      MuiTableRow: {
        root: {
          "&$selected": {
            "&:last-child": {
              "& td:first-child": {
                borderBottomLeftRadius: 16,
              },
              "& td:last-child": {
                borderBottomRightRadius: 16,
              },
            },
          },
          "&:last-child td": {
            borderBottom: 0,
          },
        },
      },
    },
    shape: {
      borderRadius: 16,
    },
    palette: {
      primary: {
        light: "#5cabed",
        main: "#127cba",
        dark: "#00508a",
      },
      secondary: {
        light: "#ff9749",
        main: "#f96617",
        dark: "#bf3400",
      },
    },
  })
);

ReactDOM.render(
  <Router>
    <CssBaseline />
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StylesProvider>
  </Router>,
  document.getElementById("root")
);
