import currency from "currency.js";
import { format } from "date-fns";

const formatCurrency = (cents, precision = 2) => {
  const decimals = parseInt(cents, 10) / 100;
  return currency(isNaN(decimals) ? 0 : decimals, {
    symbol: "$",
    separator: ",",
    decimal: ".",
    precision: precision,
  }).format();
};

const deformatCurrency = (formattedAmount) => {
  const result = formattedAmount.replace(/[,.]/g, "").replace(/[^\d.,-]/g, "");
  const number = Number(result);

  return isNaN(number) ? 0 : number;
};

const formatNumber = (number) => {
  return number
    .toString()
    .split("")
    .reverse()
    .join("")
    .match(/.{1,3}/g)
    .join(",")
    .split("")
    .reverse()
    .join("");
};

const formatPercentage = (value) => {
  return `${parseInt(value, 10)}%`;
};

const formatDate = (strDate) => format(strDate, "P");
const formatDateFull = (strDate) => format(strDate, "yyyy-LL-dd");
const formatDateMonth = (strDate) => format(strDate, "MMM yyyy");
const formatDateMonthShort = (strDate) => format(strDate, "MMM yy");

const formatAcronymName = (str) =>
  ((str || "").match(/\b\w/g) || []).slice(0, 2).join("");

export {
  formatCurrency,
  formatPercentage,
  formatDate,
  formatDateFull,
  formatDateMonth,
  formatDateMonthShort,
  deformatCurrency,
  formatNumber,
  formatAcronymName,
};
