import { API_URL } from "../constants";

const api = () => {
  const request = async ({ path, params, method, headers, body }) => {
    const url = new URL(`${API_URL}${path}`);
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        typeof value !== "undefined" && url.searchParams.set(key, value);
      }
    }
    const options = {
      ...(method && { method }),
      headers: {
        ...headers,
      },
      ...(body && { body }),
    };

    return fetch(url, options);
  };

  return {
    createPO: async (body) =>
      request({
        path: `/papp_api/po`,
        method: "POST",
        body,
      }),
  };
};

export default api;
