import React, { useCallback, useState } from "react";
import { Box, Button, Grid, TextField } from "@material-ui/core";
import useStyles from "./styles";

const HeaderComponent = ({ onGlobalChange, requiredFields }) => {
  const [data, setData] = useState({});
  const [url, setUrl] = useState("");
  const classes = useStyles();

  const onChange = useCallback(
    (key) => (e) => {
      const newData = { ...data, [key]: e.target.value };
      setData(newData);
      onGlobalChange({ header: newData });
    },
    [data, onGlobalChange]
  );

  const onChangeLogo = useCallback(
    (e) => {
      var file = e.currentTarget.files[0];
      var reader = new FileReader();

      onGlobalChange({ logo: file });

      reader.onloadend = function () {
        setUrl(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      } else {
        setUrl("");
      }
    },
    [setUrl, onGlobalChange]
  );

  return (
    <Grid container spacing={1} className={[classes.marignVertical]}>
      <Grid item xs={12}>
        <Box marginBottom={3}></Box>
      </Grid>
      <Grid item xs={12}>
        <input
          accept="image/*"
          className={classes.input}
          style={{ display: "none" }}
          id="raised-button-file"
          type="file"
          onChange={onChangeLogo}
        />
        {!url && (
          <label htmlFor="raised-button-file">
            <Button
              variant="contained"
              component="span"
              className={classes.button}
            >
              UPLOAD YOUR LOGO
            </Button>
          </label>
        )}

        {url && <img src={url} height={100} alt="logo" />}
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          onChange={onChange("po_number")}
          id="standard-required"
          label="Purchase order number"
          {...requiredFields["po_number"]}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          required
          id="standard-required"
          label="Issue date"
          onChange={onChange("date")}
          InputLabelProps={{
            shrink: true,
          }}
          {...requiredFields["date"]}
          type="date"
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="standard-required"
          label="Delivery date"
          onChange={onChange("deliveryDate")}
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          id="standard-required"
          onChange={onChange("shippingTems")}
          label="Shipping terms"
        />
      </Grid>
    </Grid>
  );
};

export default HeaderComponent;
