import React, { useCallback, useState } from "react";
import {
  TableContainer,
  Table,
  Button,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  TextField,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { formatCurrency, deformatCurrency } from "../../utils/formatters";
import useStyles from "./styles";

const EMPTY_ROW = {
  sku: "",
  description: "",
  quantity: 1,
  price: null,
  amount: null,
};

const ItemList = ({ onGlobalChange = () => {} }) => {
  const [rows, setRows] = useState([{ ...EMPTY_ROW }]);
  const classes = useStyles();

  const addLine = useCallback(() => {
    setRows([...rows, { ...EMPTY_ROW }]);
  }, [rows]);

  const deleteLine = useCallback(
    (index) => {
      setRows(rows.filter((_, i) => i !== index));
    },
    [rows]
  );

  const onChange = useCallback(
    (index, key) => (e) => {
      rows[index][key] = e.target.value;

      if (key === "price") {
        rows[index][key] = deformatCurrency(e.target.value);
      }

      setRows([...rows]);

      const subtotal = rows.reduce((subtotal, item) => {
        subtotal += item.quantity * item.price;
        return subtotal;
      }, 0);
      onGlobalChange({ items: rows, subtotal });
    },
    [rows, onGlobalChange]
  );

  return (
    <TableContainer component={Paper} className={[classes.marignVertical]}>
      <Table aria-label="simple table">
        <caption>
          <Button variant="contained" onClick={addLine}>
            Add a line item
          </Button>
        </caption>
        <TableHead>
          <TableRow>
            <TableCell>SKU</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>QTY</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  fullWidth
                  value={row.sku}
                  style={{ width: 100 }}
                  onChange={onChange(index, "sku")}
                />
              </TableCell>
              <TableCell scope="row">
                <TextField
                  fullWidth
                  required
                  value={row.description}
                  onChange={onChange(index, "description")}
                />
              </TableCell>

              <TableCell>
                <TextField
                  fullWidth
                  style={{ width: 100 }}
                  value={row.quantity}
                  type="number"
                  onChange={onChange(index, "quantity")}
                />
              </TableCell>
              <TableCell>
                <TextField
                  fullWidth
                  required
                  style={{ width: 100 }}
                  value={formatCurrency(row.price)}
                  type="currency"
                  onChange={onChange(index, "price")}
                />
              </TableCell>
              <TableCell align="right">
                {formatCurrency(row.quantity * row.price)}
              </TableCell>
              <TableCell>
                {index >= 1 && (
                  <IconButton
                    aria-label="delete"
                    className={classes.margin}
                    onClick={() => {
                      deleteLine(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ItemList;
