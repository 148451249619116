import { Button, Grid, Paper, Typography } from "@material-ui/core";
import { Box } from "@material-ui/core";
import useStyles from "./styles";
import { Alert } from "@material-ui/lab";
import React from "react";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const SuccessMessage = ({ email }) => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  return (
    <Grid
      container
      style={modalStyle}
      className={classes.modal_paper}
      justify="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center" xs={12}>
        <Alert
          variant="outlined"
          severity="success"
          style={{ marginBottom: 20, marginTop: 10 }}
        >
          Your Purchase Order is being emailed to <strong>{email}</strong>
        </Alert>

        <Typography
          variant="subtitle1"
          gutterBottom
          className={classes.modal_title1}
        >
          Tired of manual POs?
        </Typography>

        <Typography variant="subtitle1" gutterBottom>
          ControlHub offers a simple procurement system with:
        </Typography>

        <Grid
          container
          justify="space-around"
          className={classes.modal_badgets}
        >
          <Badget
            title1={"Purchasing Requests"}
            title2={"Approvals"}
            img={
              "https://uploads-ssl.webflow.com/606250622ea3c970d37b573d/606250622ea3c9002f7b57a2_OK%20to%20pay%20validation.svg"
            }
          />
          <Badget
            title1={"1-click"}
            title2={"POs"}
            img={
              "https://uploads-ssl.webflow.com/606250622ea3c970d37b573d/606250622ea3c998d07b579b_Order%20Creation.svg"
            }
          />
          <Badget
            title1={"Receiving"}
            title2={"on mobile"}
            img={
              "https://uploads-ssl.webflow.com/606250622ea3c970d37b573d/606250622ea3c9b0537b57c6_Receive.svg"
            }
          />
        </Grid>

        {/* <Box> */}

        <Button
          href="https://controlhub.com"
          variant="contained"
          size="large"
          color="secondary"
        >
          Learn more about ControlHub
        </Button>
        <Button
          size="large"
          style={{ color: "gray" }}
          onClick={() => window.location.reload(true)}
        >
          Generate another PO
        </Button>
        {/* </Box> */}
      </Box>
    </Grid>
  );
};

const Badget = ({ title1, title2, img }) => {
  const classes = useStyles();
  return (
    <Box container className={classes.modal_badget_container}>
      <Grid container item direction="column" alignItems="center">
        <Paper className={classes.modal_badget}>
          <img src={img} width={50} height={50} alt="validation" />
        </Paper>
        <Typography
          variant="subtitle2"
          gutterBottom
          alignItems="center"
          className={classes.modal_title1}
        >
          {title1}
        </Typography>
        <Typography variant="subtitle2" gutterBottom alignItems="center">
          {title2}
        </Typography>
      </Grid>
    </Box>
  );
};

export default SuccessMessage;
