import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PurchaseOrderPage from "./PurchaseOrderPage";

const Pages = () => {
  return (
    <main>
      <Switch>
        <Route exact path="/create-purchase-order">
          <PurchaseOrderPage />
        </Route>
        <Route path="*">
          <Redirect to="/create-purchase-order" />
        </Route>
      </Switch>
    </main>
  );
};

export default Pages;
