import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "2rem",
    marginTop: -135,
    marginBottom: 50,
  },
  marignVertical: {
    marginTop: "1rem",
    marginBottom: "2rem",
  },
  body: {
    height: 590,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundImage:
      "url(https://static-controlhub-files.s3.us-east-2.amazonaws.com/Cover_PO.png)",
  },
  body_title: {
    height: 280,
  },
  body_logo: {
    paddingTop: 20,
  },
  modal_paper: {
    position: "absolute",
    width: 700,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  modal_badgets: {
    marginTop: 10,
    marginBottom: 40,
  },
  modal_badget: {
    padding: 10,
    width: 70,
    height: 70,
    marginBottom: 10,
  },
  modal_badget_container: {
    width: 150,
  },
  modal_title1: {
    margin: 0,
  },
}));

export default useStyles;
