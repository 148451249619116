import React, { useState, useCallback } from "react";
import { Grid, TextField } from "@material-ui/core";
import useStyles from "./styles";

const AddressComponent = ({
  title,
  type = "",
  identifier,
  onGlobalChange,
  requiredFields = {},
}) => {
  const [data, setData] = useState({});

  const classes = useStyles();
  const xs = type === "billing" ? 6 : 4;
  const xsFull = type === "billing" ? 12 : 8;

  const onChange = useCallback(
    (key) => (e) => {
      const newData = { ...data, [key]: e.target.value };
      setData(newData);
      onGlobalChange({ [identifier]: newData });
    },
    [data, onGlobalChange, identifier]
  );

  return (
    <Grid container spacing={1} className={[classes.marignVertical]}>
      <Grid item xs={12}>
        {title}
      </Grid>

      <Grid item xs={xs}>
        <TextField
          fullWidth
          required={type !== "shipping"}
          label="Company name"
          value={data.companyName}
          onChange={onChange("companyName")}
          {...requiredFields["companyName"]}
        />
      </Grid>
      <Grid item xs={xs}>
        <TextField
          fullWidth
          required={type === "billing"}
          label="Email"
          type="email"
          value={data.email}
          onChange={onChange("email")}
          {...requiredFields["email"]}
        />
      </Grid>
      {type !== "billing" && (
        <Grid item xs={xs}>
          <TextField
            fullWidth
            label="Phone number"
            type="phone"
            value={data.phone}
            onChange={onChange("phone")}
          />
        </Grid>
      )}

      <Grid item xs={xsFull}>
        <TextField
          fullWidth
          label="Address"
          value={data.address}
          onChange={onChange("address")}
        />
      </Grid>
      <Grid item xs={xs}>
        <TextField
          fullWidth
          label="City"
          value={data.city}
          onChange={onChange("city")}
        />
      </Grid>

      <Grid item xs={xs}>
        <TextField
          fullWidth
          label="State"
          value={data.state}
          onChange={onChange("state")}
        />
      </Grid>
      <Grid item xs={xs}>
        <TextField
          fullWidth
          label="Zip Code"
          value={data.zipcode}
          onChange={onChange("zipcode")}
        />
      </Grid>
      <Grid item xs={xs}>
        <TextField
          fullWidth
          label="Country"
          value={data.country}
          onChange={onChange("country")}
        />
      </Grid>
    </Grid>
  );
};

export default AddressComponent;
