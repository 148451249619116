import { Box, Container, Grid } from "@material-ui/core";
import React from "react";
import useStyles from "./styles";
const Title = () => {
  const classes = useStyles();

  return (
    <section className={classes.body}>
      <Container maxWidth="sm">
        <Grid container className={classes.body_title}>
          <Box
            justifyContent="center"
            display="flex"
            flexDirection="column"
            color="white"
            textAlign={"center"}
            fontSize={19}
          >
            <Box margin={4}>
              <a href="https://controlhub.com">
                <img
                  src="https://static-controlhub-files.s3.us-east-2.amazonaws.com/logo_w.png"
                  alt="logo"
                  width={150}
                />
              </a>
            </Box>
            <Box fontSize={39} fontWeight={"bold"} marginBottom={2}>
              Free Purchase Order Generator
            </Box>
            <Box marginBottom={2}>
              Are you looking for a purchase order example or for a purchase
              order excel template?{" "}
              <strong>Use our free PO software instead!</strong> Generate your
              purchase order online with Controlhub’s free po generator.
            </Box>
            <Box>
              <strong>Step 1:</strong> Fill in the blanks of our free purchase
              order template
            </Box>
            <Box marginBottom={2}>
              <strong>Step 2:</strong> Receive the PO in PDF format in your
              email
            </Box>
            *Please note that the P.O. will not be sent to your vendor
          </Box>
        </Grid>
      </Container>
    </section>
  );
};

export default Title;
