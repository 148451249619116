import { useMemo, useState } from "react";
import getAPI from "./api";

const useControlHub = () => {
  const [api] = useState(getAPI());

  const hook = useMemo(() => ({ api }), [api]);

  return hook;
};

export default useControlHub;
