import { createContext, useContext } from "react";

export const ControlHubContext = createContext({
  user: {},
  features: {},
  notify: ({
    duration = 5000,
    message,
    severity = "info",
    title,
    urgent = true,
  }) => {},
});

export const useControlHubContext = () => useContext(ControlHubContext);
