import {
  Container,
  Grid,
  Paper,
  TextField,
  Button,
  Modal,
  CircularProgress,
} from "@material-ui/core";
import React, { useCallback, useState } from "react";
import useStyles from "../components/PurchaseOrder/styles";
import AddressComponent from "../components/PurchaseOrder/AddressComponent";
import HeaderComponent from "../components/PurchaseOrder/HeaderComponent";
import ItemList from "../components/PurchaseOrder/ItemList";
import Title from "../components/PurchaseOrder/Title";
import useControlHub from "../hooks";
import SuccessMessage from "../components/PurchaseOrder/SuccessMessage";
import { formatCurrency, deformatCurrency } from "../utils/formatters";

const PurchaseOrderPage = () => {
  const [data, setData] = useState({ otherCost: 0, subtotal: 0 });
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();
  const { api } = useControlHub();

  const requiredFieldTemplate = {
    required: true,
    herlperText: "This field is required.",
    type: "text",
  };

  const [requiredFields, setRequiredFields] = useState({
    billingAddress: {
      full_name: { ...requiredFieldTemplate },
      companyName: { ...requiredFieldTemplate },
      email: { ...requiredFieldTemplate, type: "email" },
    },
    vendorAddress: {
      companyName: { ...requiredFieldTemplate },
      email: { type: "email", required: false },
    },
    shippingAddress: {
      email: { type: "email", required: false },
    },
    header: {
      po_number: { ...requiredFieldTemplate },
      date: { ...requiredFieldTemplate },
    },
    // items: {
    //   description: { ...requiredFieldTemplate },
    //   price: { ...requiredFieldTemplate },
    // },
  });

  const onGlobalChange = useCallback(
    (element) => {
      setData({ ...data, ...element });
    },
    [data]
  );

  const onChangeOtherCost = useCallback(
    (e) => {
      const value = e.target.value;

      onGlobalChange({ otherCost: deformatCurrency(value) });
    },
    [onGlobalChange]
  );

  const onChangeNote = useCallback(
    (e) => {
      onGlobalChange({ notes: e.target.value });
    },
    [onGlobalChange]
  );

  const onChangeFullname = useCallback(
    (e) => {
      onGlobalChange({
        billingAddress: {
          ...data["billingAddress"],
          full_name: e.target.value,
        },
      });
    },
    [onGlobalChange, data]
  );

  const isEmailValid = (email) => {
    const regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.([a-zA-Z]{2,4})+$/g;
    return regExp.test(email);
  };

  const isValid = useCallback(() => {
    let result = true;
    Object.keys(requiredFields).forEach((field) => {
      Object.keys(requiredFields[field]).forEach((key) => {
        const value = (data[field] ?? {})[key];

        let isEmpty = (value ?? "") === "";
        let isInvalid = isEmpty;

        console.log(requiredFields[field][key], value);

        // ignore if it is empty and not required
        if (isEmpty && !requiredFields[field][key].required) {
          isInvalid = false;
        }

        // invalid email?
        if (
          !isEmpty &&
          requiredFields[field][key].type === "email" &&
          !isEmailValid(value)
        ) {
          isInvalid = true;
          requiredFields[field][key]["herlperText"] = "Invalid email";
        }

        result = result ? !isInvalid : result;
        requiredFields[field][key]["error"] = isInvalid;
      });
    });

    setRequiredFields({ ...requiredFields });

    return result;
  }, [data, requiredFields]);

  const onSubmit = useCallback(async () => {
    if (!isValid()) return;

    setLoading(true);
    const form_data = new FormData();

    for (var key in data) {
      const value = key === "logo" ? data.logo : JSON.stringify(data[key]);
      form_data.append(key, value);
    }

    try {
      await api.createPO(form_data);
      setModal(true);
    } catch (error) {}
    setLoading(false);
  }, [api, data, isValid]);

  return (
    <>
      <Title />

      <Container maxWidth="md" component={Paper} className={classes.paper}>
        <Grid container justify="space-between" spacing={1}>
          <Grid container xs={6}>
            <AddressComponent
              title="BILL TO"
              type="billing"
              onGlobalChange={onGlobalChange}
              identifier={"billingAddress"}
              requiredFields={requiredFields.billingAddress}
            />
          </Grid>
          <Grid xs={5}>
            <HeaderComponent
              onGlobalChange={onGlobalChange}
              requiredFields={requiredFields.header}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1}>
          <Grid container item xs={6}>
            <AddressComponent
              title={"VENDOR"}
              type="vendor"
              onGlobalChange={onGlobalChange}
              identifier={"vendorAddress"}
              requiredFields={requiredFields.vendorAddress}
            />
          </Grid>
          <Grid container item xs={6}>
            <AddressComponent
              title={"SHIP TO"}
              type="shipping"
              onGlobalChange={onGlobalChange}
              identifier={"shippingAddress"}
              requiredFields={requiredFields.shippingAddress}
            />
          </Grid>
        </Grid>

        <Grid container>
          <ItemList onGlobalChange={onGlobalChange} />
        </Grid>

        <Grid container spacing={1} justify="space-between">
          <Grid container item xs={6}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Contact Name"
                value={data.billingAddress?.full_name}
                onChange={onChangeFullname}
                {...requiredFields.billingAddress["full_name"]}
              />
            </Grid>
            <TextField
              fullWidth
              style={{ width: "100%" }}
              label="Notes / Comments for vendor"
              multiline
              rows={4}
              variant="outlined"
              value={data.notes}
              onChange={onChangeNote}
            />
          </Grid>
          <Grid container item xs={4} spacing={1}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label="Subtotal"
                type="currency"
                value={formatCurrency(data.subtotal)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                required
                label="Taxes, Shipping and other fees"
                type="currency"
                value={formatCurrency(data.otherCost)}
                onChange={onChangeOtherCost}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                fullWidth
                disabled
                label="Total Cost"
                type="currency"
                value={formatCurrency(data.subtotal + data.otherCost)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justify="flex-end"
              style={{ marginTop: 10 }}
            >
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onSubmit}
                  disabled={loading}
                >
                  Generate PO
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Container>

      <Modal open={modal}>
        <SuccessMessage email={data.billingAddress?.email} />
      </Modal>
    </>
  );
};

export default PurchaseOrderPage;
