import React, { useState } from "react";
import Pages from "./pages";
// import TopBar from "./components/Topbar";
// import Sidebar from "./components/Sidebar";
import { ControlHubContext } from "./contexts/controlhub-context";

function App() {
  const [context] = useState({});

  return (
    <div>
      <ControlHubContext.Provider value={context}>
        <Pages />
      </ControlHubContext.Provider>
    </div>
  );
}

export default App;
